<app-loading-overlay [loading]="matches.length === 0"></app-loading-overlay>
<div class="jumbotron" style="margin-top: 50px">
  <h1 class="text-center">Up Next</h1>
  <p class="text-center m-4 h2">
    View the next four matches for your team this season and plan ahead!
  </p>
</div>
<div class="container">
  <div class="card mt-4" *ngFor="let match of matches">
    <div class="d-flex flex-row position-relative">
      <div class="sidebar card m-2 bg-dark sticky-top">
        <div class="card-title text-center h1 text-white">
          {{ match.roundname }}
        </div>
      </div>

      <div class="d-flex flex-column w-100">
        <div
          class="gameDetails h-100 mt-2 mr-2 mb-2"
          style="
              --bg: url({{ match.venueDetails.bgSrc }})
            "
        >
          <div
            class="p-4 bg-dark h-100 text-white d-flex flex-column justify-content-center"
          >
            <div class="w-100">
              <h3 class="card-title h1 d-flex justify-content-center">
                <div class="d-flex justify-content-start">
                  <div
                    class="team-logo"
                    style="--logo: url({{ match.homeTeamLogoUri }})"
                  ></div>
                  <span class="ml-3 d-block">{{ match.hteam }}</span>
                </div>
                <span class="ml-3 mr-3 d-block">vs</span>
                <div class="d-flex justify-content-start">
                  <span class="mr-3 d-block">{{ match.ateam }}</span>
                  <div
                    class="team-logo"
                    style="--logo: url({{ match.awayTeamLogoUri }})"
                  ></div>
                </div>
              </h3>
              <div class="card-title text-center h4">
                {{ match.localtime | date: "EEEE, dd MMMM" }}
                <fa-icon [icon]="faAt"></fa-icon>
                {{ match.venue }}
              </div>
              <div class="card-title text-center mt-4">
                <a
                  href="{{ match.venueDetails.infoURL }}"
                  class="btn btn-primary btn-lg text-center"
                  *ngIf="match.complete < 100"
                  style="height: fit-content"
                >
                  Tickets & Venue Info
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
