<div class="card mt-4">
  <div class="d-flex flex-row position-relative">
    <div class="sidebar card m-2 bg-dark sticky-top">
      <div class="card-title text-center h1 text-white">
        {{ match.roundname }}
      </div>
    </div>

    <div class="d-flex flex-column w-100">
      <div
        class="gameDetails mt-2 mr-2"
        style="
            --bg: url({{ match.venueDetails.bgSrc }})
          "
      >
        <div class="p-4 bg-dark text-white d-flex justify-content-between">
          <div class="w-100">
            <h3 class="card-title text-center">
              {{ match.hteam }} vs {{ match.ateam }}
            </h3>
            <div class="card-title text-center">
              {{ match.localtime | date: "EEEE, dd MMMM" }}
              <fa-icon [icon]="faAt"></fa-icon>
              {{ match.venue }}
            </div>
          </div>
          <a
            href="{{ match.venueDetails.infoURL }}"
            class="btn btn-primary"
            *ngIf="match.complete < 100"
            style="height: fit-content"
            >Tickets & Venue Info</a
          >
        </div>
      </div>
      <div class="d-flex flex-column mt-3 stats">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <img
              src="{{ match.homeTeamLogoUri }}"
              alt="{{ match.hteam }} logo"
              width="100"
            />

            <div class="card flex">
              <table class="table">
                <thead>
                  <tr>
                    <th>Score</th>
                    <th>Goals</th>
                    <th>Behinds</th>
                    <th *ngIf="match.complete === 100"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ match.hscore }}
                    </td>
                    <td>
                      {{ match.hgoals }}
                    </td>
                    <td>
                      {{ match.hbehinds }}
                    </td>
                    <td
                      *ngIf="match.complete === 100"
                      style="width: 100px; padding: 0.75rem 0 0 !important"
                    >
                      <fa-icon
                        [icon]="faTrophy"
                        style="color: gold; outline: gold"
                        size="2x"
                        *ngIf="match.winnerteamid === match.hteamid; else loser"
                      ></fa-icon>
                      <ng-template #loser>
                        <fa-icon
                          [icon]="faTimes"
                          size="2x"
                          style="color: red; outline: red"
                        ></fa-icon>
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex flex-row mt-3">
            <img
              src="{{ match.awayTeamLogoUri }}"
              alt="{{ match.ateam }} logo"
              width="100"
            />

            <div class="card mb-2 flex">
              <table class="table">
                <thead>
                  <tr>
                    <th>Score</th>
                    <th>Goals</th>
                    <th>Behinds</th>
                    <th *ngIf="match.complete === 100"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ match.ascore }}
                    </td>
                    <td>
                      {{ match.agoals }}
                    </td>
                    <td>
                      {{ match.abehinds }}
                    </td>
                    <td
                      *ngIf="match.complete === 100"
                      style="width: 100px; padding: 0.75rem 0 0 !important"
                    >
                      <fa-icon
                        [icon]="faTrophy"
                        size="2x"
                        style="color: gold; outline: gold"
                        size="2x"
                        *ngIf="
                          match.winnerteamid === match.ateamid;
                          else loser2
                        "
                      ></fa-icon>
                      <ng-template #loser2>
                        <fa-icon
                          [icon]="faTimes"
                          size="2x"
                          style="color: red; outline: red"
                        ></fa-icon>
                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
