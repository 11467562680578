import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.styl'],
})
export class LoadingOverlayComponent implements OnInit {
  @Input()
  get loading(): any {
    return this._loading;
  }
  set loading(loading: any) {
    this._loading = loading;
  }
  private _loading = true;
  constructor() {}

  ngOnInit(): void {}
}
