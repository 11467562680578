<footer
  class="footer bg-dark text-white p-4 d-flex justify-content-between mt-4"
>
  <a href="/home" class="navbar-brand">
    <img class="mr-2" src="/assets/myafllogo.png" />myAFL
  </a>
  <p class="text-right">
    Coded and Designed by Dewaun Ayers <br />
    <a href="https://www.linkedin.com/in/dewaun-ayers/" target="_blank">
      LinkedIn
    </a>
  </p>
</footer>
