import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Team, Match } from '../types';

@Component({
  selector: 'app-rivalry',
  templateUrl: './rivalry.component.html',
  styleUrls: ['./rivalry.component.styl'],
})
export class RivalryComponent implements OnInit {
  myTeam: Team.Details & { standings: Team.Standings };
  rivalTeam: Team.Details & { standings: Team.Standings };
  matches: Array<Match> = [];
  standings: Array<Team.Standings> = [];

  constructor(public dataService: DataService) {
    this.myTeam = this.dataService.getTeam('team');
    this.rivalTeam = this.dataService.getTeam('rival');
  }

  async ngOnInit(): Promise<void> {
    this.matches = await this.dataService.getRivalMatches(
      this.myTeam.id,
      this.rivalTeam.id
    );
    this.standings = await this.dataService.getStandings();
    this.myTeam.standings = this.standings.filter(
      (data: any) => data.id === this.myTeam.id
    )[0];
    this.rivalTeam.standings = this.standings.filter(
      (data: any) => data.id === this.rivalTeam.id
    )[0];
  }
}
