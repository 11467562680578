<div class="card">
  <table class="table">
    <tbody>
      <tr>
        <th>Rank</th>
        <td>{{ standings.rank }}</td>
      </tr>
      <tr>
        <th>Wins</th>
        <td>{{ standings.wins }}</td>
      </tr>
      <tr>
        <th>Losses</th>
        <td>{{ standings.losses }}</td>
      </tr>
      <tr>
        <th>Draws</th>
        <td>{{ standings.draws }}</td>
      </tr>
      <ng-container *ngIf="!preview">
        <tr>
          <th>Games Played</th>
          <td>{{ standings.played }}</td>
        </tr>
        <tr>
          <th>For</th>
          <td>{{ standings.for }}</td>
        </tr>
        <tr>
          <th>Against</th>
          <td>{{ standings.against }}</td>
        </tr>
        <tr>
          <th>Goals For</th>
          <td>{{ standings.goals_for }}</td>
        </tr>
        <tr>
          <th>Goals Against</th>
          <td>{{ standings.goals_against }}</td>
        </tr>
        <tr>
          <th>Behinds For</th>
          <td>{{ standings.behinds_for }}</td>
        </tr>
        <tr>
          <th>Behinds Against</th>
          <td>{{ standings.behinds_against }}</td>
        </tr>
        <tr>
          <th>Points</th>
          <td>{{ standings.pts }}</td>
        </tr>
        <tr>
          <th>Percentage</th>
          <td>{{ standings.percentage | number }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
