import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import type { Match } from '../types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl'],
})
export class HomeComponent implements OnInit {
  upcomingMatches: Array<Match> = [];
  constructor(public dataService: DataService) {}

  async ngOnInit(): Promise<void> {
    const matchData = await this.dataService.getMatchesByQueryAndLimit(
      'q=games&year=2021&complete=0'
    );

    this.upcomingMatches = matchData.slice(0, 3);
  }
}
