import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import type { Match, Team } from '../types';

@Component({
  selector: 'app-up-next',
  templateUrl: './up-next.component.html',
  styleUrls: ['./up-next.component.styl'],
})
export class UpNextComponent implements OnInit {
  faAt = faAt;
  myTeam: Team.Details;
  matches: Array<Match> = [];
  constructor(public dataService: DataService) {
    this.myTeam = this.dataService.getTeam('team');
  }

  async ngOnInit(): Promise<void> {
    this.matches = await this.dataService.getMatchesByQueryAndLimit(
      `q=games&year=2021&complete=0`,
      4,
      this.myTeam.id
    );
    console.log(this.matches);
  }
}
