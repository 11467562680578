<app-loading-overlay [loading]="matches.length === 0"></app-loading-overlay>
<div class="jumbotron" style="margin-top: 50px">
  <h1 class="text-center">Results</h1>
  <p class="text-center m-4 h2">
    Keep track of your team's results this season. <br />
    Never let a game's stats pass you by.
  </p>
</div>
<div class="container">
  <div class="row">
    <div class="col col-md-12 p-3">
      <div class="dropdown float-right">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          data-display="static"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <fa-icon [icon]="faFilter"></fa-icon>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg-right"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="dropdown-item">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                [formControl]="winningMatchesControl"
                (change)="onChangeWinningMatches()"
              />
              <label class="form-check-label" for="defaultCheck1">
                WinningMatchesOnly
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-match-details
    *ngFor="let match of matches"
    [match]="match"
  ></app-match-details>
</div>
