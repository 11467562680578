<div class="container mt-4">
  <h2 class="text-center mt-4">Your AFL Rivals</h2>
  <div class="row mt-4 d-flex justify-content-between">
    <div class="col col-md-5">
      <h3 class="card-title text-center m-4">Your Team</h3>
      <div
        [className]="'card border-0 selectedTeam ' + myTeam?.abbrev"
        style="
          --bgImage: url(/assets/{{
          encodeTeamName(myTeam?.name) | lowercase
        }}.jpeg)
        "
      >
        <div
          class="team team-left"
          style="--bg: url({{ myTeam?.logo }});"
        ></div>
      </div>
      <h4 class="card-title text-center m-4">{{ myTeam?.name }}</h4>
    </div>
    <div class="col col-md-2 d-flex flex-column justify-content-end">
      <p class="text-center"><strong style="font-size: 68px">VS</strong></p>
    </div>
    <div class="col col-md-5">
      <h3 class="card-title text-center m-4">Rival Team</h3>
      <div
        [className]="'card border-0 selectedTeam ' + rivalTeam?.abbrev"
        style="
          --bgImage: url(/assets/{{
          encodeTeamName(rivalTeam?.name) | lowercase
        }}.jpeg);
        "
      >
        <div
          class="team team-right"
          style="--bg: url({{ rivalTeam?.logo }});"
        ></div>
      </div>
      <h4 class="card-title text-center m-4">{{ rivalTeam?.name }}</h4>
    </div>
  </div>
  <ng-container *ngIf="rivalMatches.length > 0; else noMatchesFound">
    <app-match-details
      *ngFor="let match of rivalMatches"
      [match]="match"
    ></app-match-details>
    <div class="row mt-4">
      <div class="col col-md-12 text-center">
        <a href="/rivalry" class="btn btn-danger align-self-center"
          >View More Matches and Stats!</a
        >
      </div>
    </div>
  </ng-container>
  <ng-template #noMatchesFound>
    <div class="alert alert-info w-100 text-center" role="alert">
      There are no matches between your team and your rival yet.
    </div>
  </ng-template>
</div>
