import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VenuesService } from './venues.service';
import { Match, Predictions, Source, Team } from './types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  resourceURI = 'https://www.squiggle.com.au';
  baseURL = 'https://api.squiggle.com.au';

  constructor(private http: HttpClient, private venuesService: VenuesService) {}

  public getResourceURI = () => this.resourceURI;

  public async getTeams() {
    const { teams } = await this.http
      .get<any>(`${this.baseURL}?q=teams`)
      .toPromise();

    return teams.map((team: Team.Details) => {
      return {
        ...team,
        logo: `${this.resourceURI}${team.logo}`,
      };
    });
  }

  private getTeamLogo(teams: Array<Team.Details>, teamId: number) {
    const team = teams.filter(({ id }: any) => id === teamId);
    return team[0].logo;
  }

  private myTeamMatchesFilter = (
    teamId: number | undefined,
    condition: boolean
  ) =>
    condition
      ? (game: any) => game.ateamid === teamId || game.hteamid === teamId
      : () => true;

  public async getMatchesByQueryAndLimit(
    query: string,
    limit: number = 3,
    teamId?: number
  ): Promise<Array<Match>> {
    const teams = await this.getTeams();
    const { games } = await this.http
      .get<any>(`${this.baseURL}?${query}`)
      .toPromise();

    const sliceArgs = limit > 0 ? [0, limit] : [0];
    const sortedGames = games
      .sort(
        ({ localtime: d1 }: any, { localtime: d2 }: any) =>
          new Date(d1).getTime() - new Date(d2).getTime()
      )
      .filter(this.myTeamMatchesFilter(teamId, !!teamId))
      .slice(...sliceArgs);

    const mappedGames = await Promise.all<Match>(
      sortedGames.map(async (game: any) => {
        const awayTeamLogoUri = this.getTeamLogo(teams, game.ateamid);
        const homeTeamLogoUri = this.getTeamLogo(teams, game.hteamid);

        return {
          ...game,
          awayTeamLogoUri,
          homeTeamLogoUri,
          venueDetails: {
            ...this.venuesService.getVenueDetails(game.venue),
          },
        };
      })
    );

    return mappedGames;
  }

  public updateTeam(type: string, team: string) {
    const parsedTeam = JSON.parse(team);
    const rival = JSON.parse(window.localStorage.getItem('rival') || '{}');
    const myTeam = JSON.parse(window.localStorage.getItem('team') || '{}');
    if (type === 'team') {
      if (rival.id === parsedTeam.id) {
        window.localStorage.removeItem('rival');
      }
    } else {
      if (myTeam.id === parsedTeam.id) {
        window.localStorage.removeItem('team');
      }
    }

    window.localStorage.setItem(type, team);
  }

  public getTeam(type: string) {
    const team = window.localStorage.getItem(type);
    if (team) {
      return JSON.parse(team);
    }

    return null;
  }

  public async getRivalMatches(
    teamId: number,
    rivalTeamId: number
  ): Promise<Array<Match>> {
    const teams = await this.getTeams();
    const { games } = await this.http
      .get<any>(`${this.baseURL}?q=games&year=2021&complete=100`)
      .toPromise();
    const sortedGames = games
      .filter(({ ateamid, hteamid }: any) => {
        const hasMyTeam = ateamid === teamId || hteamid === teamId;
        const hasRivalTeam = ateamid === rivalTeamId || hteamid === rivalTeamId;

        return hasMyTeam && hasRivalTeam;
      })
      .sort(
        ({ localtime: d1 }: any, { localtime: d2 }: any) =>
          new Date(d1).getTime() - new Date(d2).getTime()
      );

    const mappedGames = await Promise.all<Match>(
      sortedGames.map(async (game: any) => {
        return {
          ...game,
          awayTeamLogoUri: this.getTeamLogo(teams, game.ateamid),
          homeTeamLogoUri: this.getTeamLogo(teams, game.hteamid),
          venueDetails: {
            ...this.venuesService.getVenueDetails(game.venue),
          },
        };
      })
    );
    return mappedGames;
  }

  public async getSources() {
    const { sources } = await this.http
      .get<{ sources: Array<Source> }>(`${this.baseURL}?q=sources`)
      .toPromise();
    return sources.map((source: Source) => {
      return {
        ...source,
        icon: source.icon.length > 0 ? `${this.resourceURI}${source.icon}` : '',
      };
    });
  }

  private addSourceDetails(tip: Predictions.tip, sources: Array<Source>) {
    const source = sources.filter(({ id }: Source) => id === tip.sourceid)[0];
    return {
      ...tip,
      sourceUrl: source.url,
      sourceIconUrl: source.icon,
    };
  }

  public getMatch(matchId: number, matches: Array<Match>) {
    const match = matches.filter(({ id }: Match) => id === matchId);
    return match[0];
  }

  public async getPredictions(teamId: number) {
    const teams = await this.getTeams();
    const sources = await this.getSources();
    const matches = await this.getMatchesByQueryAndLimit(
      'q=games&year=2021',
      0
    );
    const { tips } = await this.http
      .get<{ tips: Array<Predictions.tip> }>(`${this.baseURL}?q=tips&year=2021`)
      .toPromise();

    const filteredTips = tips
      .filter(
        (tip: Predictions.tip) =>
          tip.ateamid === teamId || tip.hteamid === teamId
      )
      .sort(
        ({ date: d1 }: Predictions.tip, { date: d2 }: Predictions.tip) =>
          new Date(d1).getTime() - new Date(d2).getTime()
      );

    const tipsObj: any = {};
    for (const tip of filteredTips) {
      const tipWithSource = this.addSourceDetails(tip, sources);
      if (!tipsObj[tip.gameid]) {
        const gameDetails = this.getMatch(tip.gameid, matches);

        tipsObj[tip.gameid] = {
          game: gameDetails,
          tips: [tipWithSource],
        };
      } else {
        tipsObj[tip.gameid].tips.push(tipWithSource);
      }
    }

    return Object.keys(tipsObj).map((key: string) => tipsObj[key]);
  }

  private winningMatchesFilter = (
    winnerteamid: number,
    teamId: number,
    condition: boolean
  ) => (condition ? winnerteamid === teamId : true);

  public async getSeasonResults(
    teamId: number,
    winningMatchesOnly: boolean
  ): Promise<Array<Match>> {
    const teams = await this.getTeams();
    const { games } = await this.http
      .get<any>(`${this.baseURL}?q=games&year=2021&complete=100`)
      .toPromise();
    const sortedGames = games.sort(
      ({ localtime: d1 }: any, { localtime: d2 }: any) =>
        new Date(d1).getTime() - new Date(d2).getTime()
    );

    const filteredGames = sortedGames.filter(
      (game: any) =>
        (game.ateamid === teamId || game.hteamid === teamId) &&
        this.winningMatchesFilter(game.winnerteamid, teamId, winningMatchesOnly)
    );

    const mappedGames = await Promise.all<Match>(
      filteredGames.map(async (game: any) => {
        const awayTeamLogoUri = this.getTeamLogo(teams, game.ateamid);
        const homeTeamLogoUri = this.getTeamLogo(teams, game.hteamid);

        return {
          ...game,
          awayTeamLogoUri,
          homeTeamLogoUri,
          venueDetails: {
            ...this.venuesService.getVenueDetails(game.venue),
          },
        };
      })
    );

    return mappedGames;
  }

  public async getStandings() {
    const { standings } = await this.http
      .get<{ standings: Array<Team.Standings> }>(
        `${this.baseURL}?q=standings&year=2021`
      )
      .toPromise();

    return standings;
  }
}
