<div class="jumbotron" style="margin-top: 50px">
  <h1 class="text-center">Select Your Teams</h1>

  <p class="text-center m-4 h2">
    Drag and drop the selectors below to pick your team and your rival team!
  </p>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col col-md-3">
      <div
        [className]="
          'card border-0 d-flex flex-column justify-content-end selectedTeam ' +
          myTeam?.abbrev
        "
        style="
          --bgImage: url(/assets/{{
          encodeTeamName(myTeam?.name) | lowercase
        }}.jpeg)
        "
      ></div>
      <h2 class="card-title text-center m-4">{{ myTeam?.name }}</h2>
      <app-standings
        *ngIf="myTeam?.standings"
        [standings]="myTeam.standings"
        [preview]="true"
      ></app-standings>
    </div>
    <div class="col col-md-6">
      <div class="row d-flex justify-content-between">
        <div class="row teams col-md-12" style="position: relative">
          <div
            id="myTeamSelector"
            class="team-selector d-flex justify-content-center"
            cdkDragBoundary=".teams"
            cdkDrag
            (cdkDragMoved)="onMyTeamSelectMove($event)"
            joyrideStep="myTeamSelector"
            [stepContent]="myTeamSelectorContent"
          >
            <strong
              style="
                position: absolute;
                top: -24px;
                background-color: black;
                color: white;
                width: 140px;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
              "
              >Your Team</strong
            >
            <div cdkDragHandle>
              <svg width="24px" fill="green" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </div>
          <div
            id="rivalTeamSelector"
            class="team-selector"
            cdkDragBoundary=".teams"
            cdkDrag
            (cdkDragMoved)="onRivalTeamSelectMove($event)"
            joyrideStep="rivalTeamSelector"
            [stepContent]="rivalTeamSelectorContent"
          >
            <strong
              style="
                position: absolute;
                top: -24px;
                background-color: black;
                color: white;
                width: 140px;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
              "
              >Rival Team</strong
            >
            <div cdkDragHandle>
              <svg width="24px" fill="red" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
          </div>
          <div
            class="col col-md-2 mt-4"
            *ngFor="let team of availableTeams; let i = index"
          >
            <div
              id="{{ team.id }}"
              class="btn btn-lg team"
              style="--bg: url({{ team.logo }});"
              attr.data-team="{{ JSON.stringify(team) }}"
            ></div>
            <p class="text-center">
              {{ team?.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-md-3">
      <div
        [className]="
          'card border-0 d-flex flex-column justify-content-end selectedTeam ' +
          rivalTeam?.abbrev
        "
        style="
          --bgImage: url(/assets/{{
          encodeTeamName(rivalTeam?.name) | lowercase
        }}.jpeg);
        "
      ></div>
      <h2 class="card-title text-center m-4">
        {{ rivalTeam?.name }}
      </h2>
      <app-standings
        *ngIf="rivalTeam?.standings"
        [standings]="rivalTeam.standings"
        [preview]="true"
      ></app-standings>
    </div>
  </div>
</div>
<ng-template #myTeamSelectorContent>
  <p>
    Drag this selector by the middle handle to select <strong>Your Team</strong>
  </p>
</ng-template>
<ng-template #rivalTeamSelectorContent>
  <p>
    Drag this selector by the middle handle to select a
    <strong>Rival Team</strong>
  </p>
</ng-template>
