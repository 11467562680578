import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JoyrideModule } from 'ngx-joyride';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { VenuesService } from './venues.service';
import { TeamSelectComponent } from './team-select/team-select.component';
import { HomeRivalsComponent } from './home-rivals/home-rivals.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PredictionsComponent } from './predictions/predictions.component';
import { ResultsComponent } from './results/results.component';
import { MatchDetailsComponent } from './match-details/match-details.component';
import { UpNextComponent } from './up-next/up-next.component';
import { RivalryComponent } from './rivalry/rivalry.component';
import { StandingsComponent } from './standings/standings.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { PredictionsTableComponent } from './predictions-table/predictions-table.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TeamSelectComponent,
    HomeRivalsComponent,
    PredictionsComponent,
    ResultsComponent,
    MatchDetailsComponent,
    UpNextComponent,
    RivalryComponent,
    StandingsComponent,
    LoadingOverlayComponent,
    PredictionsTableComponent,
  ],
  imports: [
    JoyrideModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    DragDropModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [VenuesService],
  bootstrap: [AppComponent],
})
export class AppModule {}
