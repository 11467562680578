import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '../data.service';
import {
  faTrophy,
  faAt,
  faFilter,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import type { Predictions, Team } from '../types';
@Component({
  selector: 'app-predictions',
  templateUrl: './predictions.component.html',
  styleUrls: ['./predictions.component.styl'],
})
export class PredictionsComponent implements OnInit {
  faTrophy = faTrophy;
  faAt = faAt;
  faFilter = faFilter;
  faTimes = faTimes;
  faCheck = faCheck;

  myTeam: Team.Details & Team.Standings;
  private unfilteredPredictions: Predictions.tips = [];
  predictions: Predictions.tips = [];
  pastRoundsControl = new FormControl(false);

  constructor(public dataService: DataService) {
    this.myTeam = this.dataService.getTeam('team');
  }

  async ngOnInit(): Promise<void> {
    this.unfilteredPredictions = await await this.dataService.getPredictions(
      this.myTeam.id
    );

    this.setPredictionsRange(this.pastRoundsControl.value);
  }

  private setPredictionsRange(viewPastRounds: boolean) {
    if (viewPastRounds) {
      this.predictions = this.unfilteredPredictions;
    } else {
      this.predictions = this.unfilteredPredictions.filter(
        (tip: Predictions.tip) => tip.game.complete < 100
      );
    }
  }

  public onChangeViewPastRounds() {
    this.setPredictionsRange(this.pastRoundsControl.value);
  }
}
