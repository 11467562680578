import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '../data.service';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import type { Team, Match } from '../types';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.styl'],
})
export class ResultsComponent implements OnInit {
  faFilter = faFilter;
  myTeam: Team.Details;
  matches: Array<Match> = [];
  winningMatchesControl = new FormControl(false);
  constructor(public dataService: DataService) {
    this.myTeam = this.dataService.getTeam('team');
  }

  async ngOnInit(): Promise<void> {
    this.setMatchRange(this.winningMatchesControl.value);
  }

  public async setMatchRange(winningMatchesBool: boolean) {
    this.matches = await this.dataService.getSeasonResults(
      this.myTeam.id,
      winningMatchesBool
    );
  }

  public onChangeWinningMatches() {
    this.setMatchRange(this.winningMatchesControl.value);
  }
}
