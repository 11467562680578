import { Component, OnInit, NgZone } from '@angular/core';
import { DataService } from '../data.service';
import type { Team } from '../types';
declare var $: any;

type TTeamMeta = {
  position: Team.Position;
  standings: Team.Standings;
};

type TTeam = Team.Details & TTeamMeta;

@Component({
  selector: 'app-team-select',
  templateUrl: './team-select.component.html',
  styleUrls: ['./team-select.component.styl'],
})
export class TeamSelectComponent implements OnInit {
  JSON: any;
  availableTeams: Array<TTeam> = [];
  myTeam: TTeam;
  rivalTeam: TTeam;
  standings: Array<Team.Standings> = [];

  constructor(public dataService: DataService, private ngZone: NgZone) {
    this.JSON = JSON;
    this.myTeam = this.dataService.getTeam('team');
    this.rivalTeam = this.dataService.getTeam('rival');
  }

  public onMyTeamSelectMove(event: any) {
    const { pointerPosition } = event;

    this.availableTeams.forEach((team: any) => {
      if (this.myTeam.id !== team.id) {
        if (
          pointerPosition.x >= team.position.left &&
          pointerPosition.x <= team.position.left + 100 &&
          pointerPosition.y >= team.position.top &&
          pointerPosition.y <= team.position.top + 100
        ) {
          this.ngZone.run(() => {
            this.myTeam = team;
            this.dataService.updateTeam('team', JSON.stringify(team));
          });
        }
      }
    });
  }

  public onRivalTeamSelectMove(event: any) {
    const { pointerPosition } = event;

    this.availableTeams.forEach((team: any) => {
      if (this.rivalTeam.id !== team.id) {
        if (
          pointerPosition.x >= team.position.left &&
          pointerPosition.x <= team.position.left + 100 &&
          pointerPosition.y >= team.position.top &&
          pointerPosition.y <= team.position.top + 100
        ) {
          this.ngZone.run(() => {
            this.rivalTeam = team;
            this.dataService.updateTeam('rival', JSON.stringify(team));
          });
        }
      }
    });
  }

  private generatePosition(el: any) {
    const { left: eLeft, top: eTop } = el.offset();
    return {
      left: eLeft - 20,
      top: eTop - 8,
    };
  }

  private getTeamStandings(id: number) {
    return this.standings.filter((data: any) => data.id === id)[0];
  }

  public encodeTeamName(str: string = '') {
    return encodeURIComponent(str);
  }

  private setTeamMetaData() {
    this.availableTeams.forEach((team: TTeam) => {
      team.position = this.generatePosition($(`#${team.id}`));
      team.standings = this.getTeamStandings(team.id);
    });
  }

  async ngOnInit(): Promise<void> {
    this.standings = await this.dataService.getStandings();
    this.availableTeams = await this.dataService.getTeams();
    let [selectedTeam, selectedRival] = [
      this.dataService.getTeam('team'),
      this.dataService.getTeam('rival'),
    ];
    if (!selectedTeam && !selectedRival) {
      selectedTeam = this.availableTeams[0];
      selectedRival = this.availableTeams[this.availableTeams.length - 1];
      this.dataService.updateTeam('team', JSON.stringify(selectedTeam));
      this.dataService.updateTeam('rival', JSON.stringify(selectedRival));
    }

    this.myTeam = selectedTeam;
    this.rivalTeam = selectedRival;
    $(document).ready(() => {
      // set all positions, standings
      this.setTeamMetaData();
      // set my team positions
      const container = $(`.teams`);
      const { top, left } = container.offset();
      const myTeamEl = $(`#${this.myTeam.id}`);
      const myTeamSelector = $(`#myTeamSelector`);
      this.myTeam.position = this.generatePosition(myTeamEl);
      this.myTeam.standings = this.getTeamStandings(this.myTeam.id);
      myTeamSelector.css('top', `${this.myTeam?.position?.top - top}px`);
      myTeamSelector.css('left', `${this.myTeam?.position?.left - left}px`);

      const rivalTeamEl = $(`#${this.rivalTeam.id}`);
      const rivalTeamSelector = $(`#rivalTeamSelector`);
      this.rivalTeam.position = this.generatePosition(rivalTeamEl);
      this.rivalTeam.standings = this.getTeamStandings(this.rivalTeam.id);
      rivalTeamSelector.css('top', `${this.rivalTeam?.position?.top - top}px`);
      rivalTeamSelector.css(
        'left',
        `${this.rivalTeam?.position?.left - left}px`
      );
    });
  }
}
