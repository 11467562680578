import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import {
  faTrophy,
  faFilter,
  faTimes,
  faCheck,
  faSort,
  faSortAmountUpAlt,
  faSortAmountDown,
} from '@fortawesome/free-solid-svg-icons';

import type { Predictions, SortConfig } from '../types';
@Component({
  selector: 'app-predictions-table',
  templateUrl: './predictions-table.component.html',
  styleUrls: ['./predictions-table.component.styl'],
})
export class PredictionsTableComponent implements OnInit {
  faTrophy = faTrophy;
  faFilter = faFilter;
  faTimes = faTimes;
  faCheck = faCheck;
  faSort = faSort;
  faSortAmountUpAlt = faSortAmountUpAlt;
  faSortAmountDown = faSortAmountDown;
  @Input()
  get match(): Predictions.match {
    return this._match;
  }
  set match(match: Predictions.match) {
    this._match = match;
  }
  private _match = {} as Predictions.match;

  @Input()
  get tips(): Predictions.tips {
    return this._tips;
  }
  set tips(tips: Predictions.tips) {
    this._tips = tips;
  }
  private _tips = [] as Predictions.tips;

  tips$: Observable<Predictions.tips>;
  sortedColumn$ = new BehaviorSubject<string>('');
  sortConfig = {} as SortConfig;

  sortDirection$ = this.sortedColumn$.pipe(
    scan<string, SortConfig>(
      (sort, val) => {
        return sort.col === val
          ? { col: val, dir: sort.dir === 'desc' ? 'asc' : 'desc' }
          : { col: val, dir: 'desc' };
      },
      { dir: 'desc', col: '' }
    )
  );

  constructor() {
    this.tips$ = of(this.tips);
  }

  ngOnInit(): void {
    this.tips$ = combineLatest([of(this.tips), this.sortDirection$]).pipe(
      map(([list, sort]) => {
        return !sort.col ? list : this.sortByColumn(list, sort.col, sort.dir);
      })
    );
    this.sortDirection$.subscribe((value) => {
      this.sortConfig = value;
    });
  }

  private generateUpdatedString(time: number, unit: string): string {
    return `${time} ${unit}${time > 1 ? 's' : ''} ago`;
  }

  public formatLastUpdated(updated: string) {
    const updateDate: Date = new Date(updated);
    const today: Date = new Date();
    const seconds = Math.floor((today.valueOf() - updateDate.valueOf()) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const useDays = days > 0;
    const useHours = !useDays && hours > 0 && hours < 24;
    const useMinutes = !useHours && minutes > 0 && minutes < 60;

    if (useDays) return this.generateUpdatedString(days, 'day');
    if (useHours) return this.generateUpdatedString(hours, 'hour');
    if (useMinutes) return this.generateUpdatedString(minutes, 'minute');

    return this.generateUpdatedString(seconds, 'second');
  }

  sortOn(column: string) {
    this.sortedColumn$.next(column);
  }

  sortByColumn(
    list: Predictions.tips = [],
    col: SortConfig['col'],
    dir: SortConfig['dir'] = 'desc'
  ): Predictions.tips {
    const sorted = list.sort((a: Predictions.tip, b: Predictions.tip) => {
      let first = a[col];
      let second = b[col];
      if (col !== 'updated') {
        first = +first;
        second = +second;
      }
      if (first > second) {
        return dir === 'desc' ? 1 : -1;
      }
      if (first < second) {
        return dir === 'desc' ? -1 : 1;
      }
      return 0;
    });

    return sorted;
  }
}
