import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as StaticVenueData from './staticVenueData.json';

@Injectable({
  providedIn: 'root',
})
export class VenuesService {
  imageURL = 'https://resources.afl.com.au/photo-resources/';
  venueURL = 'https://www.afl.com.au/venues/';

  constructor(private http: HttpClient) {}

  getVenueDetails(venueName: string) {
    const venue = (StaticVenueData as { [key: string]: any })['default'][
      venueName
    ];

    return {
      imageSrc: `${this.imageURL}${venue.imageSrc}`,
      bgSrc: `${this.imageURL}${venue.bgSrc}`,
      infoURL: `${this.venueURL}${venue.venueId}`,
    };
  }
}
