import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PredictionsComponent } from './predictions/predictions.component';
import { ResultsComponent } from './results/results.component';
import { RivalryComponent } from './rivalry/rivalry.component';
import { TeamSelectComponent } from './team-select/team-select.component';
import { UpNextComponent } from './up-next/up-next.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'predictions', component: PredictionsComponent },
  { path: 'team-select', component: TeamSelectComponent },
  { path: 'results', component: ResultsComponent },
  { path: 'up-next', component: UpNextComponent },
  { path: 'rivalry', component: RivalryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
