import { Component, Input, OnInit } from '@angular/core';
import type { Team } from '../types';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.styl'],
})
export class StandingsComponent implements OnInit {
  @Input()
  get standings(): Team.Standings {
    return this._standings;
  }
  set standings(standings: Team.Standings) {
    this._standings = standings;
  }
  private _standings = {} as Team.Standings;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(preview: boolean) {
    this._preview = preview;
  }
  private _preview = false;

  constructor() {}

  ngOnInit(): void {}
}
