<table class="table">
  <thead>
    <tr>
      <th>Source</th>
      <th>Tip</th>
      <ng-container *ngIf="match.complete === 100">
        <th>
          <a (click)="sortOn('correct')" class="sortable-header">
            Correct
            <ng-container
              *ngIf="sortConfig.col !== 'correct'; else sortIconsCorrect"
            >
              <fa-icon [icon]="faSort"></fa-icon>
            </ng-container>
            <ng-template #sortIconsCorrect>
              <fa-icon
                [icon]="faSortAmountUpAlt"
                *ngIf="sortConfig.dir === 'desc'"
              ></fa-icon>
              <fa-icon
                [icon]="faSortAmountDown"
                *ngIf="sortConfig.dir === 'asc'"
              ></fa-icon>
            </ng-template>
          </a>
        </th>
        <th>
          <a (click)="sortOn('bits')" class="sortable-header">
            Bits
            <ng-container *ngIf="sortConfig.col !== 'bits'; else sortIconsBits">
              <fa-icon [icon]="faSort"></fa-icon>
            </ng-container>
            <ng-template #sortIconsBits>
              <fa-icon
                [icon]="faSortAmountUpAlt"
                *ngIf="sortConfig.dir === 'desc'"
              ></fa-icon>
              <fa-icon
                [icon]="faSortAmountDown"
                *ngIf="sortConfig.dir === 'asc'"
              ></fa-icon>
            </ng-template>
          </a>
        </th>
        <th>
          <a (click)="sortOn('err')" class="sortable-header">
            Error
            <ng-container *ngIf="sortConfig.col !== 'err'; else sortIconsErr">
              <fa-icon [icon]="faSort"></fa-icon>
            </ng-container>
            <ng-template #sortIconsErr>
              <fa-icon
                [icon]="faSortAmountUpAlt"
                *ngIf="sortConfig.dir === 'desc'"
              ></fa-icon>
              <fa-icon
                [icon]="faSortAmountDown"
                *ngIf="sortConfig.dir === 'asc'"
              ></fa-icon>
            </ng-template>
          </a>
        </th>
      </ng-container>
      <th>
        <a (click)="sortOn('confidence')" class="sortable-header">
          Confidence
          <ng-container
            *ngIf="sortConfig.col !== 'confidence'; else sortIconsConfidence"
          >
            <fa-icon [icon]="faSort"></fa-icon>
          </ng-container>
          <ng-template #sortIconsConfidence>
            <fa-icon
              [icon]="faSortAmountUpAlt"
              *ngIf="sortConfig.dir === 'desc'"
            ></fa-icon>
            <fa-icon
              [icon]="faSortAmountDown"
              *ngIf="sortConfig.dir === 'asc'"
            ></fa-icon>
          </ng-template>
        </a>
      </th>
      <th>
        <a (click)="sortOn('margin')" class="sortable-header">
          Margin
          <ng-container
            *ngIf="sortConfig.col !== 'margin'; else sortIconsMargin"
          >
            <fa-icon [icon]="faSort"></fa-icon>
          </ng-container>
          <ng-template #sortIconsMargin>
            <fa-icon
              [icon]="faSortAmountUpAlt"
              *ngIf="sortConfig.dir === 'desc'"
            ></fa-icon>
            <fa-icon
              [icon]="faSortAmountDown"
              *ngIf="sortConfig.dir === 'asc'"
            ></fa-icon>
          </ng-template>
        </a>
      </th>
      <ng-container *ngIf="match.complete < 100">
        <th>
          <a (click)="sortOn('updated')" class="sortable-header">
            Last Updated
            <ng-container
              *ngIf="sortConfig.col !== 'updated'; else sortIconsUpdated"
            >
              <fa-icon [icon]="faSort"></fa-icon>
            </ng-container>
            <ng-template #sortIconsUpdated>
              <fa-icon
                [icon]="faSortAmountUpAlt"
                *ngIf="sortConfig.dir === 'asc'"
              ></fa-icon>
              <fa-icon
                [icon]="faSortAmountDown"
                *ngIf="sortConfig.dir === 'desc'"
              ></fa-icon>
            </ng-template>
          </a>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let tip of tips$ | async">
      <tr>
        <td style="vertical-align: middle" class="h5">
          <a
            href="{{ tip.sourceUrl }}"
            attr.aria-label="link to {{ tip.source }} website"
          >
            <img
              class="source-icon"
              src="{{ tip.sourceIconUrl }}"
              alt="{{ tip.source }} logo icon"
              width="100"
              *ngIf="tip.sourceIconUrl !== ''; else sourceTitle"
            />
            <ng-template #sourceTitle>
              <span>{{ tip.source }}</span>
            </ng-template>
          </a>
        </td>
        <td style="vertical-align: middle">
          <img
            src="{{
              tip.tipteamid === match.ateamid
                ? match.awayTeamLogoUri
                : match.homeTeamLogoUri
            }}"
            alt="{{
              tip.tipteamid === match.ateamid ? match.ateam : match.hteam
            }} logo"
          />
        </td>
        <ng-container *ngIf="match.complete === 100">
          <td style="vertical-align: middle">
            <fa-icon
              [icon]="faCheck"
              [styles]="{ stroke: 'green', color: 'green' }"
              *ngIf="tip.correct === 1"
            ></fa-icon>
            <fa-icon
              [icon]="faTimes"
              [styles]="{ stroke: 'red', color: 'red' }"
              *ngIf="tip.correct === 0"
            ></fa-icon>
          </td>
          <td style="vertical-align: middle">
            {{ tip.bits }}
          </td>
          <td style="vertical-align: middle">
            {{ tip.err }}
          </td>
        </ng-container>
        <td>
          {{ tip.confidence }}
        </td>
        <td>
          {{ tip.margin }}
        </td>
        <ng-container *ngIf="match.complete < 100">
          <td>{{ formatLastUpdated(tip.updated) }}</td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>
