<app-loading-overlay [loading]="matches.length === 0"></app-loading-overlay>
<div class="jumbotron" style="margin-top: 50px">
  <h1 class="text-center">AFL Rivals</h1>
</div>
<div class="container" style="flex: 1">
  <h2 class="text-center">Stats</h2>
  <div class="row mt-4">
    <div class="col col-md-6">
      <div class="mb-4 d-flex flex-row justify-content-center">
        <div>
          <h3>{{ myTeam.name }}</h3>
          <img src="{{ myTeam.logo }}" width="100" height="100" class="logo" />
        </div>
      </div>
      <app-standings
        [standings]="myTeam.standings"
        [preview]="false"
      ></app-standings>
    </div>
    <div class="col col-md-6 mb-4">
      <div class="mb-4 d-flex flex-row justify-content-center">
        <div>
          <h3>{{ rivalTeam.name }}</h3>
          <img
            src="{{ rivalTeam.logo }}"
            width="100"
            height="100"
            class="logo"
          />
        </div>
      </div>
      <app-standings
        [standings]="rivalTeam.standings"
        [preview]="false"
      ></app-standings>
    </div>
  </div>
  <h2 class="text-center mt-4">Matches</h2>
  <ng-container *ngIf="matches.length > 0; else noMatchesFound">
    <app-match-details
      *ngFor="let match of matches; else: noMatchesFound"
      [match]="match"
    ></app-match-details>
  </ng-container>
  <ng-template #noMatchesFound>
    <div class="alert alert-info w-100 text-center" role="alert">
      There are no matches between your team and your rival yet.
    </div>
  </ng-template>
</div>
