<div class="jumbotron banner">
  <div id="video-wrapper">
    <div class="video-container">
      <h2>Your AFL. Your Way.</h2>
      <iframe
        class="promo"
        src="https://www.youtube.com/embed/GHmXCCJIPNI?autohide=1&autoplay=1&loop=1&mute=1&showinfo=0&controls=0&playlist=GHmXCCJIPNI&disablekb=1&modestbranding=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
<main class="container" style="min-height: 61.8vh">
  <div
    id="nextMatchesCarousel"
    class="carousel slide mt-4"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#nextMatchesCarousel"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#nextMatchesCarousel" data-slide-to="1"></li>
      <li data-target="#nextMatchesCarousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div
        class="carousel-item"
        [ngClass]="i === 0 ? 'active' : ''"
        *ngFor="let match of upcomingMatches; let i = index"
      >
        <div
          class="d-flex justify-content-center p-1 carousel-item-bg"
          style="--bg: url({{ match.venueDetails.bgSrc }})"
        >
          <div class="card hero-card">
            <div
              class="card-body h-100 text-center d-flex flex-column justify-content-between"
            >
              <div>
                <h5 class="card-title">
                  {{ match.hteam }} vs {{ match.ateam }}
                </h5>
                <h6 class="card-subtitle mb-2">
                  {{ match.localtime | date: "EEEE, dd MMMM @ H:mm" }}
                </h6>
              </div>
              <div class="card-block d-flex justify-content-between">
                <div
                  class="team"
                  style="--bg: url({{ match?.homeTeamLogoUri }});"
                ></div>
                <div class="d-flex justify-content-center flex-column vs">
                  VS
                </div>
                <div
                  class="team"
                  style="--bg: url({{ match?.awayTeamLogoUri }});"
                ></div>
              </div>
              <div
                class="card-block d-flex justify-content-center"
                style="margin-bottom: 2rem"
              >
                <a
                  href="{{ match.venueDetails.infoURL }}"
                  class="btn btn-primary"
                  >Tickets & Venue Info</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#nextMatchesCarousel"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#nextMatchesCarousel"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <app-home-rivals></app-home-rivals>
</main>
