import { Component, OnInit } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { DataService } from '../data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private readonly joyrideService: JoyrideService
  ) {}

  ngOnInit(): void {
    console.log();
    if (
      !this.dataService.getTeam('team') ||
      !this.dataService.getTeam('rival')
    ) {
      this.joyrideService.startTour(
        {
          steps: [
            'navbar',
            'myTeamSelector@team-select',
            'rivalTeamSelector@team-select',
          ],
        } // Your steps order
      );
    }
  }
}
