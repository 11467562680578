<app-loading-overlay [loading]="predictions.length === 0"></app-loading-overlay>
<div class="jumbotron" style="margin-top: 50px">
  <h1 class="text-center">Predictions</h1>
  <p class="text-center m-4 h2">
    Keep track of your team's tips from the top AFL stats sites!
  </p>
</div>
<div class="container">
  <div class="row">
    <div class="col col-md-12 p-3">
      <div class="dropdown float-right">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          data-display="static"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <fa-icon [icon]="faFilter"></fa-icon>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg-right"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="dropdown-item">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                [formControl]="pastRoundsControl"
                (change)="onChangeViewPastRounds()"
              />
              <label class="form-check-label" for="defaultCheck1">
                View Past Rounds
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card prediction mb-4" *ngFor="let pred of predictions">
    <div class="d-flex flex-row position-relative">
      <div class="sidebar card m-2 bg-dark sticky-top">
        <div class="card-title text-center h1 text-white">
          {{ pred.game.roundname }}
        </div>
      </div>
      <div class="d-flex flex-column w-100 m-2">
        <div class="card">
          <div
            class="gameDetails"
            style="
            --bg: url({{ pred.game.venueDetails.bgSrc }})
          "
          >
            <div class="p-4 bg-dark text-white d-flex justify-content-between">
              <div>
                <h3 class="card-title">
                  <img
                    src="{{ pred.game.homeTeamLogoUri }}"
                    alt="{{ pred.game.hteam }} logo"
                  />
                  {{ pred.game.hteam }} vs
                  <img
                    src="{{ pred.game.awayTeamLogoUri }}"
                    alt="{{ pred.game.ateam }} logo"
                  />

                  {{ pred.game.ateam }}
                </h3>
                <div class="card-title">
                  {{ pred.game.localtime | date: "EEEE, dd MMMM" }}
                  <fa-icon [icon]="faAt"></fa-icon>
                  {{ pred.game.venue }}
                </div>
              </div>
              <a
                href="{{ pred.game.venueDetails.infoURL }}"
                class="btn btn-primary"
                *ngIf="pred.game.complete < 100"
                style="height: fit-content"
                >Tickets & Venue Info</a
              >
            </div>
          </div>

          <app-predictions-table
            [match]="pred.game"
            [tips]="pred.tips"
          ></app-predictions-table>
        </div>
      </div>
    </div>
  </div>
</div>
