<nav class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">
  <a href="/home" class="navbar-brand">
    <img class="mr-2" src="/assets/myafllogo.png" />myAFL
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home" routerLinkActive="active"
          >Home</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/predictions" routerLinkActive="active"
          >Predictions</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/results" routerLinkActive="active"
          >Results</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/up-next" routerLinkActive="active"
          >Up Next</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/rivalry" routerLinkActive="active"
          >Rivalry
        </a>
      </li>
    </ul>
    <a
      joyrideStep="navbar"
      [stepContent]="selectTeamContent"
      class="btn btn-primary"
      href="/team-select"
    >
      Select Teams
    </a>
  </div>
</nav>
<ng-template #selectTeamContent>
  <p>Select your teams to continue...</p>
</ng-template>
