import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import {
  faHome,
  faRoad,
  faTrophy,
  faAt,
} from '@fortawesome/free-solid-svg-icons';
import { Match, Team } from '../types';

@Component({
  selector: 'app-home-rivals',
  templateUrl: './home-rivals.component.html',
  styleUrls: ['./home-rivals.component.styl'],
})
export class HomeRivalsComponent implements OnInit {
  faHome = faHome;
  faRoad = faRoad;
  faTrophy = faTrophy;
  faAt = faAt;
  myTeam: Team.Details;
  rivalTeam: Team.Details;
  rivalMatches: Array<Match> = [];

  constructor(public dataService: DataService) {
    this.myTeam = this.dataService.getTeam('team');
    this.rivalTeam = this.dataService.getTeam('rival');
  }

  public encodeTeamName(str: string = '') {
    return encodeURIComponent(str);
  }

  async ngOnInit(): Promise<void> {
    const matches = await this.dataService.getRivalMatches(
      this.myTeam?.id,
      this.rivalTeam?.id
    );
    this.rivalMatches = matches.slice(0, 3);
  }
}
