import { Component, OnInit, Input } from '@angular/core';
import { faTrophy, faAt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Match } from '../types';
@Component({
  selector: 'app-match-details',
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.styl'],
})
export class MatchDetailsComponent implements OnInit {
  faAt = faAt;
  faTrophy = faTrophy;
  faTimes = faTimes;
  @Input()
  get match(): Match {
    return this._match;
  }
  set match(match: Match) {
    this._match = match;
  }
  private _match = {} as Match;
  constructor() {}

  ngOnInit(): void {}
}
